/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

// jQuery
import $ from 'jquery';
import URI from 'urijs';

// ResponsiveBP
import 'responsive-bp/src/js/responsive.core.js';
import 'responsive-bp/src/js/responsive.navigation.js';
import 'responsive-bp/src/js/responsive.dropdown.js';

// Typeahead
import Bloodhound from 'corejs-typeahead';

// Sticky-Kit
import 'sticky-kit/dist/sticky-kit.js';

// Wrap element with Swiper elements
var wrapSwiper = function (element, childrenSelector = null, pagination = false, navigation = false) {
	var $element = $(element);
	$element.children(childrenSelector).addClass('swiper-slide');
	$element.wrapInner('<div class="swiper-wrapper" />');

	if (pagination) {
		$element.append('<div class="swiper-pagination" />');
	}

	if (navigation) {
		$element.append('<a class="icon-only button swiper-button-prev"><i class="icon icon-chevron-left"></i></a>');
		$element.append('<a class="icon-only button swiper-button-next"><i class="icon icon-chevron-right"></i></a>');
	}
};
var hoverSwiper = function (element, swiper) {
	var $element = $(element);

	var autoplayRunning = false;
	$element.hover(function () {
		autoplayRunning = swiper.autoplay.running;
		swiper.autoplay.stop();
	}, function () {
		if (autoplayRunning) {
			swiper.autoplay.start();
		}
		});

	if (swiper.params.autoplay.disableOnInteraction) {
		swiper.on('slideChange', function () {
			autoplayRunning = false;
		});
	}
};

// DOM ready
$(async function () {
	var $html = $('html'),
		lang = $html.attr('lang') || 'en';

	// Sticky contact box
	$("main > .contact-box").stick_in_parent();

	// Open language on touch
	$('.languages').each(async function () {
		var $this = $(this);

		$this.on('touchend', function (e) {
			$this.addClass('open');
			e.stopPropagation();
		});

		$(document).on('touchend', function () {
			$this.removeClass('open');
		});
	});

	// Keep navigation position in place when scroll bars exist
	$('[data-navigation]').each(async function () {
		var $nav = $(this),
			$button = $nav.children('button:first-child');

		// Fix positioning including scrollbar width
		$nav.on({
			'show.r.navigation': function () {
				$button.css('margin-left', -$.support.scrollbarWidth());
			},
			'hide.r.navigation': function () {
				$button.css('margin-left', 0);
			}
		});

		// Close navigation when body (with data-lock overlay) is clicked
		$html.on('click', function (e) {
			if ($(e.target).is('body')) {
				$nav.navigation('hide');
			}
		});
	});

	// Update forms
	(async function (element) {
		var $element = $(element);

		// Form focus
		$element.find('.umbraco-forms-field :input').each(function () {
			var $this = $(this),
				field = $this.parent('.umbraco-forms-field');

			$this.on({
				change: function () {
					if ($this.val().length) {
						field.addClass('has-value');
					} else {
						field.removeClass('has-value');
					}
				},
				focus: function () {
					field.addClass('focus');
				},
				blur: function () {
					field.removeClass('focus');
				}
			}).trigger('change');
		});
	})(document);

	// Filter form
	var setupFilterForm = async function () {
		var currentRequest = null,
			sendRequest = function (url, type = 'get', data = null) {
				currentRequest = $.ajax({
					type: type,
					url: url,
					data: data,
					beforeSend: function () {
						if (currentRequest !== null) {
							currentRequest.abort();
						}

						// Remove search query
						var uri = new URI(this.url);
						if (!uri.hasQuery('q')) {
							$('form.search input[type=text]').val('');
						}

						// Add loading classes
						$('h1').addClass('loading');
						$('#products').addClass('loading');
					},
					success: async function (data) {
						// Remove loading classes
						$('h1').removeClass('loading');

						// Replace elements
						var $data = $(data);
						$.each(['#result-count', '#sorting', '#filters', '#products'], function () {
							$(this).replaceWith($data.find(this));
						});

						// Setup filter form on replaced elements
						await setupFilterForm();

						// Update history/URL
						if (window.history) {
							window.history.pushState(null, null, this.url);
						}

						// Scroll to overview
						if (document.documentElement.getBoundingClientRect && document.documentElement.scrollIntoView) {
							var el = $('#products .overview')[0],
								bounding = el.getBoundingClientRect();

							if (bounding.top < 0) {
								el.scrollIntoView();
							}
						}
					}
				});
			};

		$('#filters form').submit(function (e) {
			var $form = $(this);

			sendRequest($form.attr('action'), $form.attr('method'), $form.serialize());

			e.preventDefault();
		}).each(function () {
			// Enable auto-submit
			var $form = $(this);

			$form.find('input[type=checkbox], input[type=radio]').change(function () {
				$form.submit();
			});

			// Hide submit
			$form.find('[type=submit]').hide();
		});

		// Also handle links
		$('#sorting a, #products .selected-filters a, #products .next-page a, #products .pagination a').click(function (e) {
			var $link = $(this);

			sendRequest($link.attr('href'));

			e.preventDefault();
		});

		// Enable dropdowns
		$('#filters h2, #filters .filter-group h3').each(function () {
			var $this = $(this),
				$target = $this.next();

			$this.dropdown({
				target: $target
			});
		});
	};
	setupFilterForm();

	// Product filter form
	$('.product-finder:has(form.product-filter)').each(async function () {
		var $this = $(this),
			$form = $this.find('form.product-filter'),
			$selects = $form.find('select'),
			$selectsOptions = $selects.map(function () {
				return $(this).children('option:not([value=""])');
			}),
			$count = $form.find('.count'),
			totalCount = $count.text(),
			currentRequest = null;

		// Reset all and disable next selects
		$selects.val('').slice(1).attr('disabled', true);

		$selects.change(function () {
			var $select = $(this),
				value = $select.val(),
				index = $selects.index($select) - (value ? 0 : 1);

			// Reset all next selects
			var $nextSelects = $selects.slice(index + 1).val('');

			// Disable remaining selects
			$nextSelects.slice(1).attr('disabled', true);

			if (index < 0) {
				// Update count to total
				$count.text(totalCount);
			} else {
				// Get filter keys
				var filterGroupKeys = [],
					filterKeys = {};
				$selects.each(function () {
					var $select = $(this),
						key = $select.data('key'),
						value = $select.val();

					filterGroupKeys.push(key);
					if (value) {
						filterKeys[key] = [value];
					}
				});

				// Get filters with products
				currentRequest = $.ajax({
					method: 'post',
					url: '/umbraco/api/search/productfinder?cultureInfo=' + lang + '&filterGroupKeys=' + filterGroupKeys.join('&filterGroupKeys='),
					data: JSON.stringify(filterKeys),
					contentType: 'application/json',
					beforeSend: function () {
						if (currentRequest !== null) {
							currentRequest.abort();
						}

						$this.addClass('loading');
					},
					success: function (data) {
						// Update next selects
						var $nextSelect = $nextSelects.eq(0);
						if ($nextSelect.length) {
							var nextKey = $nextSelect.data('key'),
								nextFilters = data[nextKey];

							if ($.isEmptyObject(nextFilters)) {
								// No options are available, so disable next select
								$nextSelect.attr('disabled', true);
							} else {
								// Update valid values of next select
								$nextSelect.attr('disabled', false);
								$selectsOptions[index + 1].detach().each(function () {
									var $option = $(this),
										value = $option.val(),
										isVisible = nextFilters[value] > 0;

									if (isVisible) {
										$nextSelect.append($option);
									}
								});
							}
						}

						// Update count
						var $currentSelect = $selects.eq(index),
							currentKey = $currentSelect.data('key'),
							currentFilters = data[currentKey],
							currentValue = $currentSelect.val();

						$count.text(currentFilters[currentValue]);

						$this.removeClass('loading');
					}
				});
			}
		});
	});

	// Spinner number input
	$('input[type="number"]').each(async function () {
		await import('jquery.nice-number/src/jquery.nice-number.js');

		$(this).niceNumber({
			autoSize: false,
			buttonDecrement: '<i class="icon icon-min"></i>',
			buttonIncrement: '<i class="icon icon-plus"></i>'
		}).closest('.nice-number').find('button').addClass('small primary icon-only');
	});

	// Quantity update form
	$('form.quantity').each(async function () {
		var $form = $(this),
			currentValue = null,
			currentRequest = null;

		$form.find('input[type=number]').on('change input', function () {
			var $this = $(this),
				value = $this.val();

			if (value > 0 && currentValue !== value) {
				currentValue = value;

				currentRequest = $.ajax({
					method: $form.attr('method'),
					url: $form.attr('action'),
					data: $form.serialize(),
					beforeSend: function () {
						if (currentRequest !== null) {
							currentRequest.abort();
						}

						$form.addClass('loading');
					},
					success: function () {
						$form.removeClass('loading');
					}
				});
			}
		});

		// Hide submit
		$form.find('[type=submit]').hide();
	});

	// Summary
	$('main .summary').each(function () {
		var $this = $(this);

		var maxHeight = $this.data('summary');
		if ($this.height() > maxHeight) {
			$this.addClass('collapse').css('min-height', maxHeight);

			var more = $this.data('summary-more'),
				less = $this.data('summary-less');

			var link = $('<a class="secondary arrow down" />').text(more);
			$('<p class="summary-toggler">').append(link).insertAfter($this);

			link.dropdown({
				target: $this
			}).on('show.r.dropdown hide.r.dropdown', function (e) {
				var shown = e.type === 'show';

				link.text(shown ? less : more)
					.removeClass(shown ? 'down' : 'up')
					.addClass(shown ? 'up' : 'down');
			});
		}
	});

	// Focus first input field when dropdown target is shown
	$('[data-dropdown-target]').on('shown.r.dropdown', function (e) {
		$(e.relatedTarget).find('textarea, input[type=text]').focus();
	});

	// Search typeahead
	(async function () {
		$('form.search input[type=text]').typeahead(null, {
			name: 'search',
			limit: 10,
			source: new Bloodhound({
				datumTokenizer: Bloodhound.tokenizers.whitespace,
				queryTokenizer: Bloodhound.tokenizers.whitespace,
				remote: {
					url: '/umbraco/api/search/products?cultureInfo=' + lang + '&limit=10&queryTerm=%QUERY',
					wildcard: '%QUERY'
				}
			}),
			display: 'name',
			templates: {
				suggestion: function (item) {
					return $('<figure>').append(
						item.imageUrl ? '<img src="' + item.imageUrl + '" width="90" height="60" alt="" />' : null,
						'<figcaption><p class="name">' + item.name + '<br /><small>' + item.sku + '</small></p></figcaption>'
					);
				}
			}
		}).on({
			'typeahead:select': function (e, item) {
				if (item.url) {
					window.location.href = item.url;
				} else {
					$(this).closest('form').submit();
				}
			}
		}).attr('autocomplete', 'off').closest('.twitter-typeahead').removeAttr('style');
	})();

	// Image gallery
	$('.image-gallery').each(async function () {
		var $this = $(this),
			slides = $this.find('.slides');

		if (slides.children('.slide').length <= 1) {
			// Need more than 1 image for an image gallery
			return;
		}

		const Swiper = (await import('swiper/dist/js/swiper.js')).default;

		var thumbs = $this.find('.thumbs'),
			thumbsSwiper = null;

		if (thumbs.length) {
			var isProduct = $this.closest('body.product').length > 0;

			wrapSwiper(thumbs, '.thumb');

			thumbsSwiper = new Swiper(thumbs, {
				spaceBetween: 20, // 1rem
				slidesPerView: 2,
				watchSlidesVisibility: true,
				watchSlidesProgress: true,
				breakpointsInverse: true,
				breakpoints: isProduct ? {
					360: {
						slidesPerView: 3
					},
					600: {
						slidesPerView: 4
					},
					768: {
						slidesPerView: 3
					},
					992: {
						slidesPerView: 4
					}
				} : {
					360: {
						slidesPerView: 3
					},
					600: {
						slidesPerView: 4
					},
					768: {
						slidesPerView: 5
					},
					992: {
						slidesPerView: 6
					}
				}
			});
		}

		wrapSwiper(slides, '.slide', false, true);

		// Move label outside wrapper
		slides.find('.label').appendTo(slides);

		var slidesSwiper = new Swiper(slides, {
			effect: 'fade',
			loop: true,
			keyboard: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			},
			autoplay: true,
			thumbs: {
				swiper: thumbsSwiper
			}
		});

		hoverSwiper($this, slidesSwiper);
	});

	// Sliders
	$('.slider:not(.project-slider)').each(async function () {
		const Swiper = (await import('swiper/dist/js/swiper.js')).default;
		var $this = $(this);

		wrapSwiper($this, '.slide', false, true);
		
		// Remove block row classes
		var blockClasses = $.map(['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl'], function (bp) {
			return $.map([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], function (cols) {
				return 'block-row-' + bp + '-' + cols;
			}).join(' ');
		}).join(' ');
		$this.removeClass(blockClasses).removeClass('flex-row');

		var swiper = new Swiper(this, {
			speed: 1000,
			spaceBetween: 40, // 2rem
			slidesPerView: 1,
			keyboard: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			},
			breakpointsInverse: true,
			breakpoints: {
				600: {
					slidesPerView: 2
				},
				768: {
					slidesPerView: 3
				},
				992: {
					slidesPerView: 4
				}
			},
			autoplay: $this.closest('.recently-added-products').length === 0
		});

		hoverSwiper($this, swiper);
	});

	// Sliders
	$('.project-slider').each(async function () {
		const Swiper = (await import('swiper/dist/js/swiper.js')).default;
		var $this = $(this);

		wrapSwiper($this, '.item', false, true);

		// Remove block row classes
		var blockClasses = $.map(['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl'], function (bp) {
			return $.map([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], function (cols) {
				return 'block-row-' + bp + '-' + cols;
			}).join(' ');
		}).join(' ');
		$this.removeClass(blockClasses).removeClass('flex-row');

		var swiper = new Swiper(this, {
			speed: 1000,
			spaceBetween: 40, // 2rem
			slidesPerView: 1,
			keyboard: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			},
			breakpointsInverse: true,
			breakpoints: {
				600: {
					slidesPerView: 2
				},
				992: {
					slidesPerView: 3
				}
			},
			autoplay: false
		});

		hoverSwiper($this, swiper);
	});

	// Equal height header sub navigation
	$('nav.header .container > ul').each(async function () {
		var $this = $(this),
			$uls = $this.find('ul');

		$this.children('li').on('mouseenter', function () {
			var $li = $(this).removeClass('closed');

			// Reset min-height
			$uls.css('min-height', 0);

			// Set equal height when navigation is shown next to each other (thus not full width)
			if ($li.find('.nav-container').width() !== $uls.width()) {
				// Get sub navigation height
				var height = Math.max.apply(null, $uls.map(function () {
					return $(this).outerHeight();
				}).get());

				// Set min-height
				$uls.css('min-height', height);
			}
		}).on('click', '.nav-backdrop, .nav-container', function (e) {
			if (e.target === this) {
				var $li = $(this).closest('li').addClass('closed').removeClass('open');
				$li.siblings().removeClass('open');
				$li.find('li').removeClass('open');
			}
		});

		$this.find('.toggler').click(function (e) {
			var $li = $(this).closest('li').removeClass('closed').toggleClass('open');
			$li.siblings().removeClass('open');
			$li.find('li').removeClass('open');

			e.preventDefault();
		});
	});

	// Lightboxes
	$('main:has(a[rel=lightbox]), footer:has(a[rel=lightbox])').each(async function () {
		await import(/* webpackChunkName: "lightgallery" */ 'lightgallery');
		await import(/* webpackChunkName: "lightgallery" */ 'lg-video');

		$(this).lightGallery({
			selector: 'a[rel=lightbox]',
			prevHtml: '<i class="icon icon-chevron-left"></i>',
			nextHtml: '<i class="icon icon-chevron-right"></i>',
			download: false
		});
	});
});